(function ($) {

  var $el = $('.site-select');
  var $siteSelectTrigger  = $el.find('.site-select__trigger');

  bindEvents();

  function bindEvents() {

    // Filter Menu
    $siteSelectTrigger.on( 'click', function(e) {
      e.preventDefault();
      toggleSiteSelect();
    });
  }

  function toggleSiteSelect() {
    $el.toggleClass('is-open');
  }

  // return init;

}) (jQuery);
